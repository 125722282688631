import React from 'react';
import { adHelper } from '../lib/ad_helper';
import { data } from '../lib/data';

export const NavigationMenu = () => (
  <div className="navigation-holder clearfix h-100 position-fixed">
    <div className="navigation-holder-inner clearfix">
      <div className="container clearfix">
        <div className="row cleafix h-100 overflow-hidden navigation-holder-row">
          <div className="col-md-6 clearfix nav-section-col nav-section-col-left h-100">
            <div className="nav-section-col-inner clearfix h-100">
              <ul className="nav-section-ul list-unstyled clearfix h-100">
                <li>
                  <a
                    href="/"
                    className="nav-section-link active text-decoration-none"
                  >
                    Home
                  </a>
                </li>
                {/* <li>
                  <a
                    href="about-us.html"
                    className="nav-section-link text-decoration-none"
                  >
                    About Us
                  </a>
                </li> */}
                <li>
                  <a
                    href="/agents"
                    className="nav-section-link text-decoration-none"
                  >
                    Agents
                  </a>
                </li>
                <li>
                  <a
                    href="/properties"
                    className="nav-section-link text-decoration-none"
                  >
                    Properties
                  </a>
                </li>
                {/* <li>
                  <a
                    href="blogs.html"
                    className="nav-section-link text-decoration-none"
                  >
                    Blogs
                  </a>
                </li>
                <li>
                  <a
                    href="faq.html"
                    className="nav-section-link text-decoration-none"
                  >
                    FAQ
                  </a>
                </li> */}
                <li>
                  <a
                    href={`mailto:${data.userProfile.userEmail}`}
                    className="nav-section-link text-decoration-none"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 clearfix nav-section-col nav-section-col-right">
            <div className="nav-section-col-inner clearfix bg-green-1 p-3 p-md-5">
              <a
                className="text-decoration-none d-flex flex-wrap header-contact-link text-white mb-3"
              >
                <span className="footer-contact-link-1-img-holder text-white clearfix mr-2">
                  <img
                    src="/images/footer-location.webp"
                    alt="location"
                    className="img-white-scale"
                  />
                </span>
                <p className="footer-contact-link-1-content header-contact-link font-AvenirLTStd-Roman font-16 text-white ">
                  {data.userProfile.address}
                </p>
              </a>
              <a
              href={adHelper.phoneLink(data.userProfile.phone)}
                className="text-decoration-none d-flex flex-wrap header-contact-link text-white mb-3"
              >
                <span className="footer-contact-link-1-img-holder clearfix mr-2">
                  <img
                    src="/images/footer-phone.webp"
                    alt="phone"
                    className="img-white-scale"
                  />
                </span>
                <p className="footer-contact-link-1-content header-contact-link font-AvenirLTStd-Roman font-16">
                  {data.userProfile.phone}
                </p>
              </a>
              <a
                href={`mailto:${data.userProfile.userEmail}`}
                className="text-decoration-none d-flex flex-wrap header-contact-link text-white mb-3"
              >
                <span className="footer-contact-link-1-img-holder clearfix mr-2">
                  <img
                    src="/images/footer-email.webp"
                    alt="email"
                    className="img-white-scale"
                  />
                </span>
                <p className="footer-contact-link-1-content header-contact-link font-AvenirLTStd-Roman font-16">
                  {data.userProfile.userEmail}
                </p>
              </a>
              <div className="d-flex flex-wrap clearfix footer-social-holder ml-n1 mt-5">
                <a href={data.userProfile.facebookUrl} target="_blank" rel="noreferrer"
                  className="footer-social-link bg-black text-white d-inline-flex align-items-center justify-content-center rounded-circle text-decoration-none mx-1"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href={data.userProfile.instagramUrl} target="_blank" rel="noreferrer"
                  className="footer-social-link bg-black text-white d-inline-flex align-items-center justify-content-center rounded-circle text-decoration-none mx-1"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a href={data.userProfile.youtubeUrl} target="_blank" rel="noreferrer"
                  className="footer-social-link bg-black text-white d-inline-flex align-items-center justify-content-center rounded-circle text-decoration-none mx-1"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)