import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { data } from "../lib/data"
import { loadScriptAsync } from "../lib/load_script"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { Loader } from "./Loader"
import { NavigationMenu } from "./NavigationMenu"

import '../scss/style.scss';
import '../scss/responsive.scss';
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import 'swiper/swiper-bundle.css'
import 'rc-slider/assets/index.css';

export const Layout = ({ className, children }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  useEffect(async () => {
    await loadScriptAsync("/node_modules/jquery/dist/jquery.min.js")
    await loadScriptAsync(
      "/node_modules/bootstrap/dist/js/bootstrap.bundle.min.js"
    )

    await Promise.all([
      loadScriptAsync("/plugins/owl-carousel/owl.carousel.js"),
      loadScriptAsync("/plugins/aos-master/aos.js"),
      // loadScriptAsync("/plugins/swiper-4.4.6/dist/js/swiper.min.js"),
    ])

    await loadScriptAsync("/js/theme.js")
    dispatch({ type: 'SET_READY', value: true });
  }, [])
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          name="description"
          content={data.userTagline}
        />
        <meta
          name="keywords"
          content={data.userTagline}
        />
        <meta name="robots" content="noindex,nofollow" />
        <meta property="og:title" content={data.userProfile.userDisplayName} />
        <meta
          property="og:description"
          content={data.userTagline}
        />
        <meta property="og:image" content="/images/logo.svg" />
        <link rel="icon" href="/favicon.ico?v=2" />
        <link
          href="/node_modules/bootstrap/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          href="/plugins/owl-carousel/assets/owl.carousel.min.css"
          rel="stylesheet"
        />
        <link
          href="/plugins/owl-carousel/assets/owl.theme.default.min.css"
          rel="stylesheet"
        />
        <link href="/plugins/aos-master/aos.css" rel="stylesheet" />
        <link href="/css/font-awesome.min.css" rel="stylesheet" />
        <link href="https://cdnjs.cloudflare.com/ajax/libs/mediaelement/4.2.17/mediaelementplayer.min.css" rel="stylesheet" />
        <title>{data.userProfile.userDisplayName}</title>
      </Helmet>
      <Loader />
      <div
        className={className}
        style={{ visibility: state.isReady ? "visible" : "hidden" }}
      >
        <Header />
        <NavigationMenu />
        {children}
        <Footer />
        <a id="to-top" className="">
          <i className="fa fa-chevron-up" aria-hidden="true" />
        </a>
      </div>
    </>
  )
};


Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
