/* eslint-disable react/jsx-key */
import React from "react"
import he from 'he';
import { data } from "./data"

export class adHelper {
  static firstLargePictureUrl(ad) {
    return this.largePictureUrls(ad)[0]
  }

  static largePictureUrls(ad) {
    return this.pictureUrlsBySize(ad, "extraLarge")
  }

  static thumbPictureUrls(ad) {
    return this.pictureUrlsBySize(ad, "thumb")
  }

  static pictureUrlsBySize(ad, size) {
    return []
      .concat(ad?.pictures?.picture)
      .map(x => [].concat(x?.link).filter(p => p?.rel === size))
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.href.replace(/^https?:/,''))
  }

  static lineBreaks(text) {
    return text.split("\n").reduce((total, line) => [total, <br />, line])
  }

  static toHtml(text) {
    return he.decode(`${text}`);
  }

  static parseRef(text) {
    const desc = this.toHtml(text)
    const reRef = /Property Reference #: (.*?)</
    const [, ref] = reRef.exec(desc) || []
    return ref
  }

  static propertyLink(property) {
    return `/properties/${this.parseRef(property.description).toLowerCase()}`;
  }

  static parseAgent(text) {
    const desc = this.toHtml(text)
    const reAgent = /<b>Agent Details:<\/b><br \/>(.*?)<br \/>(.*?)<br \/>(.*?)<br \/>/
    const [, name, office, area] = reAgent.exec(desc) || []
    return Object.assign({}, data.agentDetails[name], {
      name,
      office,
      area,
    })
  }

  static adById(id) {
    return [].concat(data.ads?.ad).find(x => (x.id === id || this.parseRef(x.description)?.toLowerCase() === `${id}`.toLowerCase()))
  }

  static featuredAds() {
    const { featuredIds } = data
    return this.ads().filter(x => featuredIds.includes(x.id) 
      || featuredIds.includes(this.parseRef(x.description)) 
      || /#top[^\w]/.test(this.toHtml(x.description))
    )
  }

  static ads() {
    return [].concat(data.ads?.ad)
  }

  static cleanPhone(phone = '') {
    let cleaned = phone.replace(/[^0-9]/g, "")
    if (!/^27/.test(cleaned)) cleaned = `27${cleaned.replace(/^0/,'')}`
    return cleaned.replace(/^0/,'');
  }

  static phoneLink(phone = '') {
    const cleaned = this.cleanPhone(phone);
    return `tel:+${cleaned}`
  }

  static whatsappLink(phone = '', msg = '') {
    const cleaned = this.cleanPhone(phone);
    return `https://wa.me/${cleaned}${msg ? `?text=${encodeURIComponent(msg)}` : ''}`;
  }

  static agentUrl(name) {
    return `/properties/?agent=${encodeURIComponent(name)}`
  }

  static uniqueDwellingTypes() {
    const uniqTypes = this.ads().reduce((prev, cur) => {
      const dwellingType = [].concat(cur.attributes?.attribute).find(a => a.name === 'DwellingType') || {
        "name": "DwellingType",
        "value": {
          "text": "house",
          "localizedLabel": "House"
        }
      };
      prev[dwellingType.value.localizedLabel] = (prev[dwellingType.value.localizedLabel] || 0) + 1;
      return prev;
    }, {});
    return uniqTypes;
  }

  static filterAds({
    agent,
    category,
    location,
    bedroom,
    bathroom,
    keyword,
    price = [],
    // area = [],
    type,
  }) {
    return this.ads().filter(prop => {
      if(agent && adHelper.parseAgent(prop.description).name !== agent) return false;
      if(category && Number(prop.category?.id) !== Number(category)) return false;
      if(location) {
        const re = new RegExp(location, "ig");
        if (!(re.test(prop.locations.location.localizedName) || re.test(prop.title) || re.test(prop.description))) return false;
      }
      if(bedroom && Number([].concat(prop.attributes?.attribute).find(a => a.name === 'NumberBedrooms')?.value.localizedLabel) < Number(bedroom)) return false;
      if(bathroom && Number([].concat(prop.attributes?.attribute).find(a => a.name === 'NumberBathrooms')?.value.localizedLabel) < Number(bathroom)) return false;
      if(keyword) {
        const re = new RegExp(keyword, "ig");
        if (!(re.test(prop.title) || re.test(prop.description))) return false;
      }
      if(price.length == 2 && (Number(prop.price?.amount) < (Number(price[0]) * 1000000) || Number(prop.price?.amount) > (Number(price[1]) * 1000000))) return false;
      // if(area.length == 2 && (Number(prop.?.amount) < Number(price[0]) || Number(prop.price?.amount) > Number(price[1]))) return false;
      if(type && [].concat(prop.attributes?.attribute).find(a => a.name === 'DwellingType')?.value.localizedLabel !== type) return false;
      return true;
    });
  }
}
