import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { GlobalStateContext } from '../context/GlobalContextProvider';

export const Loader = () => {
  const state = useContext(GlobalStateContext);
  const [spinning, setSpinning] = useState(!state.isReady);
  useEffect(() => {
    if (state.isReady) {
      // eslint-disable-next-line no-undef
      $('.loader').fadeOut(1000);
      setTimeout(() => setSpinning(false), 1000)
    }
  }, [state.isReady]);
  return spinning ? (
    <div className="loader align-items-center justify-content-center align-content-center">
      <div id="spinner"></div>
      <p className="font-24 text-white d-block mt-3 w-100 text-center font-Ubuntu-M">
        Please Wait...
      </p>
    </div>
  ) : null
}

Loader.propTypes = {
  ready: PropTypes.bool,
}
