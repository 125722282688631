
import React from 'react';

export const Header = () => (
  <div className="header clearfix position-fixed w-100 pt-5">
    <div className="container clearfix">
      <div className="row clearfix align-items-center">
        <div className="col-4 col-sm-4 header-logo-holder clearfix">
          <a href="/">
            <img className="logo" src="/images/logo.svg" alt="NCL Realtors" width="155" />
          </a>
        </div>

        <div className="col-8 col-sm-8 header-nav-holder clearfix">
          <ul className="header-nav clearfix m-0 d-flex align-items-center justify-content-end list-unstyled">
            {/* <li className="header-nav-li clearfix">
              <a className="header-nav-link" >
                Sk
              </a>
            </li>
            <li className="header-nav-li clearfix">
              <a className="header-nav-link" >
                En
              </a>
            </li> */}
            <li className="header-nav-li clearfix">
              <a
                className="header-nav-link navigation-opener"
              >
                <span className="header-nav-btn-line"></span>
                <span className="header-nav-btn-line"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)