import React from "react"
import { adHelper } from "../lib/ad_helper";
import { data } from "../lib/data";

export const Footer = () => (
  <div className="footer-part clearfix section position-relative">
    <img
      src="/images/footer-back-1.webp"
      alt="footer"
      className="w-100 footer-part-grass-img"
    />
    <div className="top-footer-part clearfix bg-black">
      <div className="container clearfix">
        <TopFooterContactsHolder />
        {/* <!-- top-footer-holder --> */}
        <TopFooterHolder />
      </div>
    </div>
    <div className="middle-footer-part clearfix py-4">
      <div className="container clearfix">
        <div className="footer-row-2 clearfix">
          <div className="row clearfix">
            <div className="col-sm-6 col-md-4 col-lg-6 clearfix footer-row-2-col">
              <a className="text-decoration-none d-flex flex-wrap footer-contact-link-1">
                <span className="footer-contact-link-1-img-holder clearfix mr-2">
                  <img
                    src="/images/footer-location.webp"
                    alt="location"
                    className=""
                  />
                </span>
                <p className="footer-contact-link-1-content font-AvenirLTStd-Roman font-16">
                  {data.userProfile.address}
                </p>
              </a>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 clearfix footer-row-2-col">
              <a href={adHelper.phoneLink(data.userProfile.phone)} className="text-decoration-none d-flex flex-wrap footer-contact-link-1">
                <span className="footer-contact-link-1-img-holder clearfix mr-2">
                  <img
                    src="/images/footer-phone.webp"
                    alt="phone"
                    className=""
                  />
                </span>
                <p className="footer-contact-link-1-content font-AvenirLTStd-Roman font-16">
                  {data.userProfile.phone}
                </p>
              </a>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 clearfix footer-row-2-col">
              <a
                href={`mailto:${data.userProfile.userEmail}`}
                className="text-decoration-none d-flex flex-wrap footer-contact-link-1"
              >
                <span className="footer-contact-link-1-img-holder clearfix mr-2">
                  <img
                    src="/images/footer-email.webp"
                    alt="email"
                    className=""
                  />
                </span>
                <p className="footer-contact-link-1-content font-AvenirLTStd-Roman font-16">
                  {data.userProfile.userEmail}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="last-footer-part clearfix bg-black">
      <div className="container clearfix">
        <div className="footer-row-3 clearfix py-5">
          <div className="row clearfix">
            <div className="col-md-6 clearfix footer-row-3-col">
              <p className="footer-copy-content font-AvenirLTStd-Roman font-16">
                With <i className="fa fa-heart"></i> from {' '}
                <a className="text-white" href="https://productive.me">Productive.me</a>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const TopFooterContactsHolder = () => (
  <div className="top-footer-contacts-holder clearfix mx-auto row">
    <div className="col-md-6 top-footer-contacts-holder-col top-footer-contacts-holder-col-left clearfix bg-green-2 py-4">
      <div className="row clearfix my-1 flex-nowrap">
        <div className="col col-md-3 col-sm-2 col-2 clearfix d-inline-flex align-items-center flex-wrap justify-content-center footer-cntct-left">
          <div className="top-footer-contact-icon-holder rounded-circle bg-black-gradient-1 align-items-center justify-content-center d-flex flex-wrap">
            <i className="fas fa-map-marker-alt font-20 text-white"></i>
          </div>
        </div>
        <div className="col-md-9 col-sm-10 col-10 clearfix d-inline-flex align-items-center flex-wrap footer-cntct-right">
          <div className="top-footer-contact-info-holder">
            <h4 className="text-white font-24 font-Archivo-Regular font-weight-bold">
              {data.userProfile.address}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6 top-footer-contacts-holder-col top-footer-contacts-holder-col-right clearfix bg-green-1 py-4">
      <div className="row clearfix my-1 flex-nowrap">
        <div className="col col-md-3 col-sm-2 col-2 clearfix d-inline-flex align-items-center flex-wrap justify-content-center footer-cntct-left">
          <div className="top-footer-contact-icon-holder rounded-circle bg-black-gradient-1 align-items-center justify-content-center d-flex flex-wrap">
            <i className="fas fa-paper-plane font-20 text-white"></i>
          </div>
        </div>
        <div className="col-md-9 col-sm-10 col-10 clearfix d-inline-flex align-items-center flex-wrap footer-cntct-right">
          <div className="top-footer-contact-info-holder">
            <h4 className="text-white font-24 font-Archivo-Regular font-weight-bold">
              Feel free to contact
              <br />
              <a href={`mailto:${data.userProfile.userEmail}`} className="text-decoration-none text-white">
                {data.userProfile.userEmail}
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const TopFooterHolder = () => (
  <div className="footer-row-1 clearfix pb-sm-5">
    <div className="row clearfix">
      <div className="col-md-4 col-lg-6 clearfix footer-row-1-col">
        <a className="mb-4 text-decoration-none d-table">
          <img
            src="/images/logo.svg"
            alt="NCL Realtors | Escape to the West Coast"
            width="155"
          />
        </a>
        <p className="font-30 font-Brandon_reg font-weight-bold text-white footer-logo-sub-title">
          Let’s talk real estate!
        </p>
        <h4 className="font-40 font-Brandon_reg font-weight-bold mb-4 text-gradient-green-1 footer-contact-number-1">
          <a href={adHelper.phoneLink(data.userProfile.phone)}>{data.userProfile.phone}</a>
        </h4>
        <p className="font-20 opacity-0-5 font-Brandon_reg text-white">
          Talk to us, we are great listeners and love to find the perfect escape on the West Coast.
        </p>
      </div>
      <div className="col-sm-6 col-md-4 col-lg-3 clearfix footer-row-1-col">
        <p className="font-30 font-Brandon_reg font-weight-bold text-white">
          Useful Links
        </p>
        <span className="divider-green-1 mt-2"></span>
        <ul className="list-unstyled mt-5 footer-links-ul">
          <li>
            <a href="/" className="active text-decoration-none footer-nav-link-1">
              Home
            </a>
          </li>
          <li>
            <a href="/agents" className="text-decoration-none footer-nav-link-1">
              Meet the Team
            </a>
          </li>
          <li>
            <a href="/properties" className="text-decoration-none footer-nav-link-1">
              Browse properties
            </a>
          </li>
        </ul>
      </div>
      <div className="col-sm-6 col-md-4 col-lg-3 clearfix footer-row-1-col">
        <p className="font-30 font-Brandon_reg font-weight-bold text-white">
          Get in Touch
        </p>
        <span className="divider-green-1 mt-2"></span>
        <ul className="list-unstyled mt-5 footer-links-ul">
          <li>
            <a href={`mailto:${data.userProfile.userEmail}`} className="text-decoration-none footer-nav-link-1">
              Email Us{" "}
            </a>
          </li>
          <li>
            <a href={adHelper.phoneLink(data.userProfile.phone)} className="text-decoration-none footer-nav-link-1">Call Us</a>
          </li>
        </ul>
        <div className="d-flex flex-wrap clearfix footer-social-holder ml-n1">
          <a href={data.userProfile.facebookUrl} target="_blank" rel="noreferrer" className="footer-social-link bg-green-1 text-white d-inline-flex align-items-center justify-content-center rounded-circle text-decoration-none mx-1">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href={data.userProfile.instagramUrl} target="_blank" rel="noreferrer" className="footer-social-link bg-green-1 text-white d-inline-flex align-items-center justify-content-center rounded-circle text-decoration-none mx-1">
            <i className="fab fa-instagram"></i>
          </a>
          <a href={data.userProfile.youtubeUrl} target="_blank" rel="noreferrer" className="footer-social-link bg-green-1 text-white d-inline-flex align-items-center justify-content-center rounded-circle text-decoration-none mx-1">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
)
