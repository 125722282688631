import json from "../data.json"
import merge from "deepmerge"

const overrides = {
  userProfile: {
    phone: "082-386-0582",
    userEmail: "info@nclrealtors.co.za",
    address: "1327 Pieter Cruythof, Riebeek Kasteel, Swartland, 7300",
    userTagline:
      "Escape to the West Coast | The place of friendly people\nand panoramic views",
    facebookUrl: "https://www.facebook.com/rene.swarts.5",
    instagramUrl: "https://www.instagram.com/reneswarts/",
    youtubeUrl:
      "https://youtube.com/playlist?list=PLBko4d-wzrY5Of5lLvXeuS-CIzbIMTr48",
  },
  featuredIds: [],
  agentDetails: {
    "Rene Swarts": {
      role: "Intern Agent",
      phone: "082-386-0582",
      userEmail: "rene@nclrealtors.co.za",
      facebookUrl: "https://www.facebook.com/rene.swarts.5",
      instagramUrl: "https://www.instagram.com/reneswarts/",
      youtubeUrl:
        "https://youtube.com/playlist?list=PLBko4d-wzrY5Of5lLvXeuS-CIzbIMTr48",
    },
    "Neville Lang": {
      role: "Principal",
      phone: "084-726-9669",
      userEmail: "neville@nclrealtors.co.za",
      facebookUrl: "",
      instagramUrl: "",
      youtubeUrl: "",
    },
    "Lydia Dittberner": {
      role: "Office Manager",
      phone: "082-558-5168",
      userEmail: "lydia@nclrealtors.co.za",
      facebookUrl: "",
      instagramUrl: "",
      youtubeUrl: "",
    },
  },
}

export const data = merge(json, overrides)
